<template>
  <v-row justify="center">
    <v-dialog
      persistent
      :value="open"
      @input="$emit('update:open', $event)"
      max-width="410"
    >
      <v-card>
        <v-card-title style="word-break: break-word" class="font-weight-medium text-center">
          Deseja realmente desativar este paciente?
        </v-card-title>
        <v-form ref="deletePatientForm" v-model="valid">
          <v-card-text class="text-center">
            <v-row>
              <v-col cols="12" class="pb-0">
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-subheader class="text-start">
                  <v-icon class="pr-3">mdi-information-outline</v-icon> Após o desligamento
                  do paciente, ainda será possível ativa-lo novamente.
                </v-subheader>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="">
            <v-btn
              width="49%"
              class="font-weight-light pa-5"
              color="#323F4B"
              text
              @click="onClose"
            >
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              width="49%"
              class="white--text pa-5"
              color="red"
              @click="onPressAgree"
              :disabled="!valid"
            >
              Desativar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["open"],
  data: () => ({
    valid: true,
    reason: null,
  }),
  methods: {
    onPressAgree() {
      this.$emit("accepted", this.reason);
      this.reason = "";
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
